import Vue from 'vue'
import VueRouter from 'vue-router'
import ShipMealRouter from '@/router/modules/ShipMeal'
import OtherSyncRouter from '@/router/modules/OtherSync'
import SpecialRouter from '@/router/modules/Special'
import YonghuiSyncRouter from '@/router/modules/YonghuiSync'
import SyncLinkRouter from '@/router/modules/SyncLink'
import syncPopRouter from '@/router/modules/syncPop'
import QuickSyncLinkRouter from '@/router/modules/QuickSyncLink'
import PhysicalRedeemCodeRouter from '@/router/modules/PhysicalRedeemCode'
import comboSyncRouter from '@/router/modules/comboSync'
Vue.use(VueRouter)
const router = new VueRouter({
  routes: [
    {
      path: '/',
      redirect: 'Coupon'
    },
    {
      path: '/upgrade',
      name: 'upgrade',
      meta: { title: '系统升级' },
      component: () => import('../views/upgrade.vue')
    },
    {
      path: '/Coupon',
      name: 'Coupon',
      meta: { title: '卡券商品兑换' },
      component: () => import('../views/Coupon.vue')
    },
    {
      path: '/Recharge',
      name: 'Recharge',
      meta: { title: '商品直充' },
      component: () => import('../views/Recharge.vue')
    },
    {
      path: '/Coating',
      name: 'Coating',
      meta: { title: '涂层兑换码' },
      component: () => import('../views/Coating.vue')
    },
    {
      path: '/ECoating',
      name: 'ECoating',
      meta: { title: '电商涂层兑换码' },
      component: () => import('../views/ECoating')
    },
    {
      path: '/ECoating/detail',
      name: 'ECoatingDetail',
      meta: { title: '电商涂层兑换码券码详情' },
      component: () => import('../views/ECoating/orderDetail')
    },
    {
      path: '/error',
      name: 'Error',
      meta: { title: '404页' },
      component: () => import('../views/error.vue')
    },
    {
      path: '/SeriesCode',
      name: 'SeriesCode',
      meta: { title: '涂层串码' },
      component: () => import('../views/SeriesCode.vue')
    },
    // 特殊直充兑换码需求（（1）发放手机号必须与兑换手机号一致（2）同步兑换码（3）需要专属兑换链接，在其他兑换链接不可被兑换）
    {
      path: '/SpecialRecharge',
      name: 'SpecialRecharge',
      meta: { title: '通用定制兑换' },
      component: () => import('../views/SpecialRecharge')
    },
    {
      path: '/zfb/:code',
      name: 'alipay',
      meta: { title: '订单通知' },
      component: () => import('../views/alipay')
    },
    {
      path: '/wechat/:code',
      name: 'wechat',
      meta: { title: '订单通知' },
      component: () => import('../views/wechat')
    },
    ...SyncLinkRouter,
    ...YonghuiSyncRouter,
    ...SpecialRouter,
    ...OtherSyncRouter,
    ...ShipMealRouter,
    ...QuickSyncLinkRouter,
    ...PhysicalRedeemCodeRouter,
    ...syncPopRouter,
    ...comboSyncRouter
  ]
})

export default router
